import { defineStore } from 'pinia';
import AuthService from 'src/services/AuthService';
import { UserInfo } from '../types';
import { useLocalStorage } from '@vueuse/core';

export const useUserInfoStore = defineStore('user-info', {
  state: () => {
    return useLocalStorage('user-info', {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      type: '',
      avatar: '',
      is_email_verified: false,
      onboardingDone: undefined as Date | null | undefined,
      lastAcknowledgedLevel: 1,
      petName: '',
      stripe_product_id: '',
      stripe_subscription_is_active: true,
      isAdmin: false,
      agencyId: '',
      agencyName: '',
    });
  },
  getters: {
    fullName: (state) => `${state.firstName} ${state.lastName}`
  },
  actions: {
    async fetchUserInfo() {
      const [error, data] = await AuthService.getUserInfo();
      if (error) {
        console.error(error);
        return;
      }
      if (data) {
        this.id = data.id;
        this.email = data.email;
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.type = data.type;
        this.avatar = data.avatar;
        this.is_email_verified = data.is_email_verified;
        this.onboardingDone = data.user_metadata.onboarding_done as Date | null;
        this.lastAcknowledgedLevel = data.user_metadata.last_acknowledged_level;
        this.stripe_product_id = data.stripe_product_id;
        this.stripe_subscription_is_active = data.stripe_subscription_is_active;
        this.isAdmin = data.is_admin;
        this.agencyId = data.agency_id;
        this.agencyName = data.agency_name;
      }
    },
    setUserInfo(userData: UserInfo) {
      this.id = userData.id;
      this.email = userData.email;
      this.firstName = userData.first_name;
      this.lastName = userData.last_name;
      this.type = userData.type;
      this.avatar = userData.avatar;
      this.is_email_verified = userData.is_email_verified;
      this.onboardingDone = userData.user_metadata
        ?.onboarding_done as Date | null;
      this.lastAcknowledgedLevel =
        userData.user_metadata?.last_acknowledged_level || 1;
      this.stripe_product_id = userData.stripe_product_id;
      this.stripe_subscription_is_active =
        userData.stripe_subscription_is_active;
      this.isAdmin = userData.is_admin;
      this.agencyId = userData.agency_id;
      this.agencyName = userData.agency_name;
    },
    clearUserInfo() {
      this.id = '';
      this.email = '';
      this.firstName = '';
      this.lastName = '';
      this.type = '';
      this.avatar = '';
      this.is_email_verified = false;
      this.stripe_product_id = '';
      this.isAdmin = false;
      this.stripe_subscription_is_active = true;
      this.agencyId = '';
      this.agencyName = '';
    },
    setIsEmailVerified(isEmailVerified: boolean) {
      this.is_email_verified = isEmailVerified;
    },
    setPetName(petName: string) {
      this.petName = petName;
    },
    setAvatar(avatar: string) {
      this.type = avatar;
    },
    setStripeProductId(stripeProductId: string) {
      this.stripe_product_id = stripeProductId;
    },
    async fetchStripeProductId() {
      const [error, data] = await AuthService.getUserInfo();
      if (error) {
        console.error(error);
        return;
      }
      if (data) {
        this.stripe_product_id = data.stripe_product_id;
      }
    },
    setAgencyInfo(agencyId: string, agencyName: string) {
      this.agencyId = agencyId;
      this.agencyName = agencyName;
    },
  },
  persist: true,
});
