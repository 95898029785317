import { api } from 'boot/axios';
import apiConfig from 'src/apiConfig';
import { Lesson, Quiz, QuizAnswerSubmission, Student } from 'src/types';
import logger from 'src/logger';

class MeetingService {
  async getJitsiToken(courseId: string): Promise<string> {
    try {
      const response = await api.get(apiConfig.auth.jitsiJWT, {
        params: { courseId: courseId },
      });
      return response.data.jitsi_token;
    } catch (error) {
      throw error;
    }
  }

  async createLesson(
    courseId: string,
    lessonName = 'Lesson',
    bbbInternalMeetingId: string
  ): Promise<any> {
    try {
      const response = await api.post(apiConfig.lessons.create, {
        name: lessonName,
        course: courseId,
        bbb_internal_meeting_id: bbbInternalMeetingId,
      });
      return response.data;
    } catch (error) {
      logger.error({ error: error }, 'Error creating lesson');
      throw error;
    }
  }

  async fetchLesson(lessonId: string): Promise<Lesson> {
    try {
      const url = apiConfig.lessons.fetchLesson(lessonId);
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async checkForExistingLesson(courseId: string): Promise<Lesson | null> {
    try {
      const url = apiConfig.lessons.checkForExistingLesson(courseId);
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async endLesson(lessonId: string): Promise<Lesson> {
    try {
      const url = apiConfig.lessons.end(lessonId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addStudentToLesson(lessonId: string, studentId: string): Promise<void> {
    try {
      const url = apiConfig.lessons.addStudent(lessonId);
      const data = { student_id: studentId };
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      console.error('Error in registering student for lesson:', error);
    }
  }

  async listStudentsInLesson(lessonId: string): Promise<Student[]> {
    try {
      const url = apiConfig.lessons.listStudents(lessonId);
      const response = await api.get(url);
      const students = response.data.map((studentData: any) => {
        const student: Student = {
          id: studentData.id,
          first_name: studentData.first_name,
          last_name: studentData.last_name,
          avatar: studentData.avatar,
        };
        return student;
      });
      return students;
    } catch (error) {
      console.error('Error fetching students:', error);
      throw error;
    }
  }

  async fetchQuizzes(): Promise<Quiz[]> {
    try {
      const response = await api.get(apiConfig.quizzes.getAll);
      return response.data;
    } catch (error) {
      console.error('Error fetching quizzes:', error);
      throw error;
    }
  }

  async launchQuiz(lessonId: string, quizId: string, quizMode: string, participantsInMeeting: number, quizMonsterDetails: string): Promise<any> {
    try {
      const url = apiConfig.lessons.launchQuiz(lessonId, quizId);
      const response = await api.post(url, {
        quiz_id: quizId,
        quiz_mode: quizMode,
        participants_in_meeting: participantsInMeeting,
        quiz_monster_details: quizMonsterDetails,
      });
      return response.data;
    } catch (error) {
      console.error('Error launching quiz:', error);
      throw error;
    }
  }

  async getActiveQuiz(lessonId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.getActiveQuiz(lessonId);
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching active quiz:', error);
      throw error;
    }
  }

  async joinQuiz(lessonId: string, quizSessionId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.joinQuiz(lessonId, quizSessionId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      console.error('Error joining quiz:', error);
      throw error;
    }
  }

  async fetchQuizTeacherDummy(quizId: string): Promise<Quiz> {
    try {
      const url = apiConfig.quizzes.getQuizData(quizId);
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching quiz:', error);
      throw error;
    }
  }

  async QuizModeSubmitPusher(
    lessonId: string,
    quizSessionId: string,
    quizMode: string,
    quizMonsterDetails: string
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.submitQuizMode(lessonId, quizSessionId);
      const response = await api.post(url, {
        quiz_mode: quizMode,
        quiz_monster_details: quizMonsterDetails,
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting quiz:', error);
      throw error;
    }
  }

  async submitQuizAnswer(
    lessonId: string,
    quizSessionId: string,
    questionId: string,
    answerIds: string[] | null,
    answersText: string | null,
    remainingTime: number
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.submitAnswer(lessonId, quizSessionId);
      const response = await api.post(url, {
        question_id: questionId,
        answer_ids: answerIds,
        answers_text: answersText,
        remaining_time: remainingTime,
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting quiz answer:', error);
      throw error;
    }
  }

  async goToNextQuestion(
    lessonId: string,
    quizSessionId: string,
    currentQuestionId: string
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.goToNextQuestion(lessonId, quizSessionId);
      const response = await api.patch(url, {
        current_question_id: currentQuestionId,
      });
      return response.data;
    } catch (error) {
      console.error('Error going to next question quiz answer:', error);
      throw error;
    }
  }

  async showQuizResults(lessonId: string, quizSessionId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.showQuizResults(lessonId, quizSessionId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      console.error('Error showing quiz results:', error);
      throw error;
    }
  }

  async startQuiz(
    lessonId: string,
    quizId: string,
    quizSessionId: string
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.startQuiz(lessonId, quizSessionId);
      const response = await api.post(url, {
        quiz_id: quizId,
      });
      return response.data;
    } catch (error) {
      console.error('Error starting quiz:', error);
      throw error;
    }
  }

  async registerQuizParticipants(
    lessonId: string,
    quizSessionId: string,
    studentId: string
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.registerStudentQuizParticipation(
        lessonId,
        quizSessionId
      );
      const response = await api.post(url, {
        student_id: studentId,
      });
      return response.data;
    } catch (error) {
      console.error('Error registering quiz participants:', error);
      throw error;
    }
  }
  async fetchQuizStatus(lessonId: string, quizSessionId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.fetchQuizStatus(lessonId, quizSessionId);
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching quiz status:', error);
      throw error;
    }
  }

  async getCurrentQuestion(
    lessonId: string,
    quizSessionId: string
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.getCurrentQuestion(lessonId, quizSessionId);
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching current question:', error);
      throw error;
    }
  }

  async endQuiz(lessonId: string, quizSessionId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.endQuiz(lessonId, quizSessionId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      console.error('Error ending quiz:', error);
      throw error;
    }
  }

  async editQuizSessionResponseCorrectness(lessonId: string, quizSessionId: string, questionId: string, submissions: any) {
    try {
      const url = apiConfig.lessons.editQuizSessionResponseCorrectness(lessonId, quizSessionId);
      const response = await api.post(url, {
        question_id: questionId,
        submissions: submissions
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting question submission correctness:', error);
      throw error;
    }
  }

  async launchBadgeReward(lessonId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.launchBadgeReward(lessonId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      console.error('Error starting badge reward:', error);
      throw error;
    }
  }

  async endBadgeReward(lessonId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.endBadgeReward(lessonId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      console.error('Error ending badge reward:', error);
      throw error;
    }
  }

  async startUnderstandingCheck(lessonId: string): Promise<unknown> {
    try {
      const url = apiConfig.lessons.startUnderstandingCheck(lessonId);
      const response = await api.post(url);
      return response;
    } catch (error) {
      console.error('Error when checking understanding:', error);
      throw error;
    }
  }

  async submitUnderstandingCheck(
    lessonId: string,
    understandingAnswer: boolean
  ): Promise<unknown> {
    try {
      const url = apiConfig.lessons.submitUnderstandingCheck(lessonId);
      const response = await api.post(url, {
        understanding_answer: understandingAnswer,
      });
      return response;
    } catch (error) {
      console.error('Error when checking understanding:', error);
    }
  }

  async submitBadgeReward(
    lessonId: string,
    assigneeId: string,
    badgeName: string
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.submitBadgeReward(lessonId);
      const response = await api.post(url, {
        assignee_id: assigneeId,
        name: badgeName,
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting badge reward:', error);
      throw error;
    }
  }

  async getBadgeRewardsForStudent(
    lessonId: string,
    studentId: string
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.getBadgeRewards(lessonId, studentId);
      const response = await api.get(url);
      return response.data['badge_list'];
    } catch (error) {
      console.error('Error fetching badge rewards:', error);
      throw error;
    }
  }

  async getBadgeRewards(lessonId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.getBadgeRewards(lessonId);
      const response = await api.get(url);
      return response.data['badge_list'];
    } catch (error) {
      console.error('Error fetching badge rewards:', error);
      throw error;
    }
  }

  async endUnderstandingCheck(lessonId: string): Promise<unknown> {
    try {
      const url = apiConfig.lessons.endUnderstandingCheck(lessonId);
      const response = await api.post(url);
      return response;
    } catch (error) {
      console.error('Error when checking understanding:', error);
    }
  }

  async getLeaderboard(lessonId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.getLeaderboard(lessonId);
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
      throw error;
    }
  }

  async startLeaderboardRewarding(
    lessonId: string,
    studentId: string
  ): Promise<any> {
    try {
      const url = apiConfig.lessons.getLeaderboard(lessonId);
      const response = await api.post(url, {
        assignee_id: studentId,
      });
      return response.data;
    } catch (error) {
      console.error('Error starting leaderboard rewarding:', error);
      throw error;
    }
  }

  async getChestXPAmount(lessonId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.getChestXPAmount(lessonId);
      const response = await api.get(url);
      const reward = response.data;
      console.log('reward', reward);
      return reward;
    } catch (error) {
      console.error('Error fetching chest XP amount:', error);
      throw error;
    }
  }

  async notifyChestReward(lessonId: string, chests: object[], selectedChest: number): Promise<any> {
    try {
      const url = apiConfig.lessons.getChestXPAmount(lessonId);
      const response = await api.post(url, {
        chests: chests,
        selected_chest: selectedChest,
      });
      return response.data;
    } catch (error) {
      console.error('Error notifying chest reward:', error);
      throw error;
    }
  }

  async launchPodium(lessonId: string): Promise<any> {
    try {
      const url = apiConfig.lessons.launchPodium(lessonId);
      const response = await api.post(url);
      return response.data;
    } catch (error) {
      console.error('Error launching podium:', error);
      throw error;
    }
  }

  async notifyInactivity(lessonId: string, inactiveTime: number): Promise<any> {
    try {
      const url = apiConfig.lessons.notifyInactivity(lessonId);
      const response = await api.post(url, {
        inactive_time: inactiveTime,
      });
      return response.data;
    } catch (error) {
      console.error('Error notifying inactivity:', error);
      throw error;
    }
  }

  async getTeacherReview(
    lessonId?: string,
    courseId?: string,
    teacherId?: string
  ): Promise<any> {
    try {
      const params: { [key: string]: string | undefined } = {};
      if (lessonId) params.lesson_id = lessonId;
      if (courseId) params.course_id = courseId;
      if (teacherId) params.teacher_id = teacherId;

      const response = await api.get(apiConfig.lessons.getTeacherReview, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching teacher review:', error);
      throw error;
    }
  }

  async submitTeacherReview(
    lessonId: string,
    rating: number,
    description: string
  ): Promise<any> {
    try {
      const response = await api.post(apiConfig.lessons.submitTeacherReview, {
        lesson_id: lessonId,
        rating: rating,
        description: description,
      });
      return response.data;
    } catch (error) {
      console.error('Error submitting teacher review:', error);
      throw error;
    }
  }

  async startCoBrowser(courseId: string, region: string, lessonId?: string ): Promise<any> {
    try {

      const response = await api.post(apiConfig.lessons.coBrowser, {
        course_id: courseId,
        region: region,
        lesson_id: lessonId
      });

      return response.data;
    } catch (error) {
      console.error('Error starting co-browser:', error);
      throw error;
    }
  }

  async getCoBrowserSession(courseId: string, lessonId: string): Promise<any> {
    
      const params: any = {};
      params.course_id = courseId;
      params.lesson_id = lessonId;
      const response = await api.get(apiConfig.lessons.coBrowser, {
        params: params,
      });

      return response.data;
    
  }

  async endCoBrowser(courseId: string, coBrowserSessionId: string,saveSession: boolean, lessonId?: string): Promise<any> {
    try {
      const params: any = {};
      params.course_id = courseId;
      params.co_browser_session_id = coBrowserSessionId;
      params.save_session = saveSession;
      if (lessonId) {
        params.lesson_id = lessonId;
      }

      const response = await api.delete(apiConfig.lessons.coBrowser, {
        params: params,
      });
      return response.data;
    } catch (error) {
      console.error('Error ending co-browser:', error);
      throw error;
    }
  }

  async updateLessonName(lessonId: string, name: string): Promise<any> {
    try {
      const response = await api.post(apiConfig.lessons.updateName(lessonId), {
        name: name,
      });
      return response.data;
    } catch (error) {
      console.error('Error changing lesson name:', error);
      throw error;
    }
  }

  async getLessonHistory(agency_id: string) {
    try {
      const response = await api.get(apiConfig.lessons.lessonHistory, {
        params: { agency_id },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

}

export default new MeetingService();
